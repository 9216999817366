<template>
  <NuxtLinkLocale
    href="/login"
    class="order-5 py-2.5 px-3 flex lg:hidden items-center justify-center group text-primary group-hover:text-secondary"
    v-if="status === 'unauthenticated'"
  >
    <LucideUser class="size-5 sm:size-4" :stroke-width="1.5" />
  </NuxtLinkLocale>
</template>

<script setup>
const { status } = await useAuth();
</script>

